import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Paper,
  Typography,
  makeStyles,
  Container,
  FormControl,
  InputLabel,
  Select,
  Link
} from "@material-ui/core";
import logo from "./assets/logo.png";
import countries from "./assets/country.json";

export default function App() {
  const useStyles = makeStyles((theme) => ({
    main: {
      height: "100vh"
    },
    logo: {
      objectFit: "contain",
      height: "150px"
    },
    description: {
      marginTop: "10px",
      padding: "10px",
      backgroundColor: "#efefef",
      textAlign: "justify",
      textJustify: "inter-word",
      borderRadius: "20px"
    },
    button: {
      margin: theme.spacing(1),
      marginLeft: 0
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    iconSmall: {
      fontSize: 20
    },
    root: {
      padding: theme.spacing(3, 3),
      margin: theme.spacing(3, 0),
      borderRadius: "20px"
    },
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginRight: theme.spacing(1)
    },
    footer: {
      position: "fixed",
      left: 0,
      right: 0,
      bottom: "0px",
      padding: theme.spacing(2, 2),
      textAlign: "center"
    },
    formControl: {
      marginBottom: theme.spacing(1),
      minWidth: 120
    }
  }));

  const classes = useStyles();

  // TODO add validation
  const [number, setNumber] = useState("");
  const [fullNumber, setFullNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");

  useEffect(() => {
    if (number && countryCode) {
      setFullNumber(`${countryCode.replaceAll("+", "")}${number}`);
    }
  }, [number, countryCode]);

  const openShareTray = () => {
    navigator.share({
      url: "https://csb-007wl.netlify.app/",
      title: "Whatsapp to Unknown Number"
    });
  };
  return (
    <>
      <Container maxWidth="xs" className={classes.main}>
        <center>
          <img src={logo} className={classes.logo} alt="whatsapp" />
        </center>
        <Typography variant="h5" align="center">
          Whatsapp to Unknown Number
        </Typography>
        <Typography
          variant="body2"
          component="p"
          className={classes.description}
        >
          This app will allow you to directly send a WhatsApp message to a
          number, instead of having to save the number and then texting the
          person. If the entered number is registered on WhatsApp, you will
          instantly be able to message the person through WhatsApp.
        </Typography>
        <Paper className={classes.root}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              Country Code
            </InputLabel>
            <Select
              native
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              label="Country Code"
            >
              {countries.map((item) => (
                <option
                  key={item.code}
                  value={item.dial_code}
                >{`${item.name} (${item.dial_code})`}</option>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Whatsapp Mobile"
            helperText="*10 digit Whatsapp Number Without country code"
            id="margin-normal"
            name="number"
            type="tel"
            className={classes.textField}
            onChange={(e) => setNumber(e.target.value)}
            variant="outlined"
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            href={`https://wa.me/${fullNumber}`}
            fullWidth
            {...(fullNumber.length <= 10 ? { disabled: true } : {})}
          >
            Open Whatsapp Chat
          </Button>
          {navigator.share && (
            <Button
              variant="outlined"
              fullWidth
              color="secondary"
              onClick={openShareTray}
            >
              Spread the word!
            </Button>
          )}
        </Paper>
      </Container>
      <Container maxWidth="xs" className={classes.footer} fullWidth>
        <Typography
          textAlign="center"
          variant="body1"
          fontWeight="fontWeightLight"
        >
          Created with{" "}
          <span role="img" aria-label="love">
            ❤️
          </span>{" "}
          by <a href="https://twitter.com/lazzy_ms">@lazzy_ms</a>{" "}
        </Typography>
      </Container>
    </>
  );
}
